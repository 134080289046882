import React from "react";
import { Modal } from "react-responsive-modal";
import { shallowEqual, useSelector } from "react-redux";

const ModalName = "message";

const Message = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  const { lang_json } = useSelector(
    (state) => ({
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );
  const { msg } = popup_payload ? popup_payload : { msg: "" };

  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-message`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">NOTICE</div>
      <div className="modal-content">
        <h3>{lang_json ? (lang_json[msg] ? lang_json[msg] : msg) : msg}</h3>
      </div>
      <div className="modal-button">
        {popup_can_close ? (
          <div
            className="btn btn-close"
            onClick={close_func}
            style={{ backgroundImage: `url("${lang_json?.bg_btn_ok}")` }}
          ></div>
        ) : null}
      </div>
    </Modal>
  );
};

export default Message;
