import axios from "axios";
import { err_msg, getLsObject, preload } from "helper";
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const ls = window.localStorage;

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
});

const common = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
  withCredentials: false,
});

export const toggle_popup = (bool, tipe, can_close = true, payload = null) => ({
  type: "TOGGLE_POPUP",
  bool,
  tipe,
  can_close,
  payload,
});

export const toggle_loader = (data) => ({
  type: "TOGGLE_LOADER",
  data: data,
});

export const toggle_loader_hash = (data) => ({
  type: "TOGGLE_LOADER_HASH",
  data: data,
});

export const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
});

/**
 *
 * GET Request
 *
 */
export const get_user_info = () => {
  return (dispatch) => {
    dispatch(get_translate("EN"));
    dispatch(toggle_loader(true));

    axios
      .get("oauth/check_login/")
      .then((resp) => {
        dispatch(put_data("login", resp.data.user));
        dispatch(put_data("event_start", resp.data.event_start));
        dispatch(put_data("event_end", resp.data.event_end));
        dispatch(get_history());
        dispatch(get_spin());
        dispatch(get_winner());
        dispatch(get_form());
        if (!resp.data.is_event_open) {
          dispatch(
            toggle_popup(true, "message", false, { msg: "Not in Event Period" })
          );
          dispatch(toggle_loader(false));
        }
      })
      .catch((err) => {
        dispatch(toggle_loader(false));
        dispatch(catch_error(err_msg(err)));
      });
  };
};

export const get_history = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get("/api/history/")
      .then((resp) => {
        dispatch(put_data("history", resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_spin = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get("/api/spin/")
      .then((resp) => {
        dispatch(put_data("spin_data", resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_winner = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get("/api/winner/")
      .then((resp) => {
        dispatch(put_data("winner", resp.data));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const get_form = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get("/api/form/")
      .then((resp) => {
        if (resp.data.has_submitted) {
          dispatch(
            put_data("form", {
              ...resp.data.form_data,
              agreement1: true,
              agreement2: true,
              agreement3: true,
            })
          );
        } else {
          dispatch(put_data("form", resp.data.form_data));
        }
        dispatch(put_data("has_submitted", resp.data.has_submitted));
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

/**
 *
 * POST Request
 *
 */

export const post_form = (form_data) => {
  delete form_data.agreement1;
  delete form_data.agreement2;
  delete form_data.agreement3;

  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .post("/api/form/", form_data)
      .then((resp) => {
        dispatch(toggle_popup(true, "message", true, resp.data));
        dispatch(get_form());
      })
      .catch((err) => {
        dispatch(put_data("err_form", err?.response?.data));
        // dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

export const post_spin = (dshow) => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    if (dshow) {
      ls.setItem("dshow", true);
    }
    axios
      .post("/api/spin/")
      .then((resp) => {
        dispatch(put_data("is_spin", false));
        dispatch(put_data("is_block_spin", true));
        dispatch(get_spin());

        setTimeout(() => {
          dispatch(put_data("is_spin", true));
          dispatch(put_data("spin_num", (11 - resp.data.position) % 10));
        }, 50);

        setTimeout(() => {
          dispatch(toggle_popup(true, "congrats", true, resp.data));
          dispatch(put_data("is_block_spin", false));
          dispatch(get_history());
          dispatch(get_spin());
          dispatch(get_winner());
        }, 8500);
      })
      .catch((err) => {
        dispatch(catch_error(err_msg(err)));
      })
      .then(() => {
        // dispatch(toggle_loader(false));
      });
  };
};

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang) => {
  return (dispatch) => {
    let language = getLsObject("param")?.lang.toUpperCase() || default_lang;

    const main_localize = cdn.get(`data.json?${Math.random() * 10000}`);
    const common_localize = common.get(
      `${language}.json?${Math.floor(Math.random() * 10000)}`
    );

    axios
      .all([main_localize, common_localize])
      .then((resp) => {
        const translation = resp[0].data.data
        const json = {
          ...resp[1].data,
          ...resp[0].data.data,
        };
        dispatch(put_data("lang_json", json));

        preload([
          translation.img_reward_png,
          translation.img_logo,
          translation.img_title,
          translation.img_bg_2,
          translation.img_bg_history,
          translation.img_btn_rules,
          translation.img_btn_spin,
          translation.img_spin_wheel,
          translation.img_spin2,
          translation.img_bg_popup_big,
          translation.img_bg_popup,
          translation.img_btn_fb,
          translation.img_btn_wa,
          translation.img_icon_edit,
          translation.img_spin_token,
        ]);
      })
      .catch((err) => { });
  };
};

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
  const loginErrorMapping = {
    m: `[err: ${err_msg}] Maintenance`,
    1: `[err: ${err_msg}] Error fetching Garena user data, please try close and reopen banner`,
    2: `[err: ${err_msg}] Error fetching Garena user data, please try close and reopen banner`,
    3: `[err: ${err_msg}] Missing AOV data, please try again with another account`,
    4: `[err: ${err_msg}] Missing AOV data, please try again with another account`,
    5: `[err: ${err_msg}] Account must have level 2 or higher to participate this event`,
    41: `[err: ${err_msg}] Please logout your account from the game and login again to enter the Event`,
  };

  return (dispatch) => {
    if (err_msg === "not_logged_in") {
      dispatch(toggle_popup(true, "message", false, { msg: err_msg }));
      dispatch(toggle_loader(false));
    } else if (err_msg === "Please Refresh Your Browser!") {
      dispatch(toggle_popup(true, "message", false, { msg: err_msg }));
      dispatch(toggle_loader(false));
    } else if (err_msg === "not_enough_token") {
      dispatch(toggle_popup(true, "message", true, { msg: err_msg }));
      dispatch(toggle_loader(false));
    } else if (err_msg === "maintenance") {
      dispatch(toggle_popup(true, "message", false, { msg: err_msg }));
      dispatch(toggle_loader(false));
    } else if (err_msg === "min_level_not_reached") {
      dispatch(toggle_popup(true, "message", false, { msg: err_msg }));
      dispatch(toggle_loader(false));
    } else {
      dispatch(
        toggle_popup(true, "message", false, {
          msg:
            loginErrorMapping[err_msg] ||
            `[err: ${err_msg}] Unknown error occurred`,
        })
      );
      dispatch(toggle_loader(false));
    }
    /* UNCOMMENT TO ENABLE TOO MUCH ACTION POPUP
        else {
            dispatch(toggle_popup(true, "message", true, { msg: err_msg }));
        }
        */

    if (err_msg === "invalid_region") {
      localStorage.clear();
    }
  };
};
