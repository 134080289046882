import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggle_popup } from "redux/actions/main";
import "./index.scss";

import Message from "./components/Message";
import Form from "./components/Form";
import Confirm from "./components/Confirm";
import Congrats from "./components/Congrats";
import EventInfo from "./components/EventInfo";
import Share from "./components/Share";
import ToS from "./components/ToS";



const Modals = () => {
    const dispatch = useDispatch();
    const { popup_type, popup_open, popup_payload, popup_can_close,lang_json } =
        useSelector(
            (state) => ({
                popup_type: state.main.popup_type,
                popup_open: state.main.popup_open,
                popup_payload: state.main.popup_payload,
                popup_can_close: state.main.popup_can_close,
                lang_json: state.main.lang_json
            }),
            shallowEqual
        );

    const propsModalPass = {
        popup_type,
        popup_open,
        popup_payload,
        popup_can_close,
        close_func: () => dispatch(toggle_popup(false, "")),
    };

    useEffect(() => {
        document.documentElement.style.setProperty('--img-close', `url(${lang_json?.img_popup_close})`)
    }, [lang_json]);
    

    return (
        <>
            <Message {...propsModalPass} />
            <Form {...propsModalPass} />
            <Confirm {...propsModalPass} />
            <Congrats {...propsModalPass} />
            <EventInfo {...propsModalPass} />
            <Share {...propsModalPass} />
            <ToS {...propsModalPass} />
        </>
    );
};

export default Modals;
