import React, { useState } from "react";
import { Modal } from "react-responsive-modal";
import CopyToClipboard from "react-copy-to-clipboard";
import { shallowEqual, useSelector } from "react-redux";

const ModalName = "share";

const Share = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  const baseUrl = "https://share.garena.com/?collection=gl_aov_lucky_spin"

  const onShareClick = (type) => {
    if (type === "fb") {
      const appId = "411670789656590";
      // let url =
      //   "https://www.facebook.com/dialog/share?" +
      //   "app_id=" +
      //   appId +
      //   "&display=touch" +
      //   "&href=" +
      //   encodeURIComponent(process.env.REACT_APP_BASE_URL) +
      //   "&redirect_uri=" +
      //   encodeURIComponent(process.env.REACT_APP_BASE_URL);
      let url =
        "https://www.facebook.com/dialog/share?" +
        "app_id=" +
        appId +
        "&display=page" +
        "&href=" +
        encodeURIComponent(baseUrl) +
        "&redirect_uri=https://event.aov.garena.com/spin/";

      window.location.href = url;
    } else if (type === "wa") {
      window.open(
        `https://api.whatsapp.com/send?text=${baseUrl}`
      );
    }
  };

  const [isCp, setIsCp] = useState(false);
  const { lang_json } = useSelector(
    (state) => ({
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );

  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-share`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">
        {lang_json?.txt_popup_title_share ?? "SHARE"}
      </div>
      <div className="modal-content">
        <div className="icon-container">
          <div
            className="btn btn-fb"
            style={{ backgroundImage: `url(${lang_json?.img_btn_fb})` }}
            onClick={() => onShareClick("fb")}
          />
          {/* <div className="btn btn-wa" style={{ backgroundImage: `url(${lang_json?.img_btn_wa})` }} onClick={() => onShareClick("wa")} /> */}
          <CopyToClipboard
            text={baseUrl}
            onCopy={() => {
              setIsCp(true);
              setTimeout(() => setIsCp(false), 2000);
            }}
          >
            <div
              className="btn btn-copy"
              style={{ backgroundImage: `url(${lang_json?.img_copy_link})` }}
            />
          </CopyToClipboard>
        </div>
        <h4>{lang_json.desc_fb_share}</h4>
        <h5>{isCp ? "Link Copied" : ""}</h5>
      </div>
    </Modal>
  );
};

export default Share;
