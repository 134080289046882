import React, { lazy, Suspense, useEffect } from "react";
import { Switch, HashRouter as Router, Route } from "react-router-dom";
import { fixView, getUrlParam } from "helper";

// Component
import ErrorBoundary from "ErrorBoundary";
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import LoadingScreen from "helper/component/loadingscreen";
import { componentLoader } from "helper";
import Modals from "components/Modals";

// REDUX
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")));
const Auth = lazy(() => componentLoader(() => import("pages/Auth")));

export const Main = (props) => {
	const dispatch = useDispatch();
	const { lang_json } = useSelector((state) => state.main, shallowEqual);

	useEffect(() => {
		const ls = window.localStorage;
		const token = getUrlParam("token");
		const err = getUrlParam("err");

		if (err) {
			dispatch(mainActions.catch_error(err));
			ls.setItem("err", err);
		} else {
			ls.removeItem("err");
		}

		if (token) {
			ls.setItem("token", token);
			window.location.href = process.env.REACT_APP_BASE_URL;
		}

		// preload([
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Reward_png.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Logo.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Title.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/BG-2.jpg",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/BG_History.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Btn_Rules.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Btn_Spin.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/SPIN WHEEL.png",
		//   "https://cdngarenanow-a.akamaihd.net/gstaticid/AOV/luckyspininfinix2/Spin2.png",
		// ]);
	}, [dispatch]);

	var w = Math.max(
		document.documentElement.clientWidth,
		window.innerWidth || 0
	);
	var h = Math.max(
		document.documentElement.clientHeight,
		window.innerHeight || 0
	);
	// document.getElementsByTagName("html, body").css({"width":w,"height":h});
	window.onresize = () => {
		document.getElementsByTagName("body")[0].style.width = w;
		document.getElementsByTagName("body")[0].style.height = h;
		document.getElementsByTagName("html")[0].style.width = w;
		document.getElementsByTagName("html")[0].style.height = h;
	};
	useEffect(() => {
		fixView();
	}, []);
	return (
		<Router basename="/" hashType={"noslash"}>
			<div id="Main" className="main-panel">
				<ErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={false} type="portrait" />
						<Loader loader={props?.main?.loader} />
						<Modals />
						<Helmet>
							<meta charSet="utf-8" />
							<title>{lang_json?.meta_title}</title>
						</Helmet>
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Home} />
								<Route exact path="/auth" component={Auth} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</Suspense>
				</ErrorBoundary>
			</div>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	main: state.main,
});

const mapDispatchToProps = (dispatch) => ({
	actionsMain: bindActionCreators(mainActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
