import React, { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { post_spin } from "redux/actions/main";
import { Modal } from "react-responsive-modal";

const ModalName = "confirm";

const Confirm = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  const dispatch = useDispatch();
  const { spin_data, lang_json } = useSelector(
    (state) => ({
      spin_data: state.main.spin_data,
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );
  const [dShow, setDShow] = useState(false);

  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-confirm`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">CONFIRMATION</div>
      <div className="modal-content">
        <h4 className="margin">
          Use {spin_data?.spin_price}{" "}
          <div
            className="token-img"
            style={{ backgroundImage: `url(${lang_json?.img_spin_token})` }}
          />{" "}
          to spin?
        </h4>
        <div className="form-checkbox">
          <input
            checked={dShow}
            className="checkbox"
            type="checkbox"
            onChange={() => setDShow(!dShow)}
          />
          <label>Don't show this again</label>
        </div>
      </div>
      <div className="modal-button">
        <div
          className="btn btn-close"
          style={{ backgroundImage: `url("${lang_json?.bg_btn_ok}")` }}
          onClick={() => {
            close_func();
            dispatch(post_spin(dShow));
          }}
        ></div>
      </div>
    </Modal>
  );
};

export default Confirm;
