const main_state = {
  login: false,
  loader: false,
  loader_hash: false,
  lang_json: false,

  // Form
  form: false,
  has_submitted: false,

  // Spin
  is_spin: false,
  spin_num: 0,
  is_block_spin: false,

  // Modal
  popup_open: true,
  popup_type: "",
  popup_can_close: true,
  popup_payload: {
    msg: "",
  },

  // API
  history: false,
  event_start: false,
  event_end: false,
  spin_data: false,
  winner: false,
  err_form: false,
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case "PUT_DATA":
      return { ...state, [action.key]: action.data };
    case "TOGGLE_LOADER":
      return { ...state, loader: action.data };
    case "TOGGLE_LOADER_HASH":
      return { ...state, loader_hash: action.data };
    case "TOGGLE_POPUP":
      return {
        ...state,
        popup_open: action.bool,
        popup_type: action.tipe,
        popup_can_close: action.can_close,
        popup_payload: action.payload,
      };
    default:
      return state;
  }
};

export default main;
