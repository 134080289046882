import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toggle_popup } from "redux/actions/main";
import { Modal } from "react-responsive-modal";

const ModalName = "tos";

const ToS = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  const dispatch = useDispatch();
  const { lang_json } = useSelector(
    (state) => ({
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );

  const onCloseTOS = (is_agree) => {
    dispatch(toggle_popup(true, "form", false));
    if (is_agree) {
      popup_payload?.cb("agreement1", true);
    }
  };

  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-tos`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">
        TERM OF SERVICES
      </div>
      <div className="modal-content">
        <div
          className="tos"
          dangerouslySetInnerHTML={{
            __html: lang_json?.term_of_services,
          }}
        />
        <div className="modal-button">
          <div
            className="btn btn-agree"
            onClick={onCloseTOS}
            style={{ backgroundImage: `url("${lang_json?.bg_btn_agree_continue}")` }}
          ></div>
        </div>
      </div>
    </Modal>
  );
};

export default ToS;
