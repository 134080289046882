import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";

const ModalName = "congrats";

const Congrats = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  // const handleCopyCode = () => {
  //   var input = document.createElement("input");
  //   input.setAttribute("value", popup_payload?.voucher_code);
  //   document.body.appendChild(input);
  //   input.select();
  //   var result = document.execCommand("copy");
  //   document.body.removeChild(input);

  //   document.getElementsByClassName("text-copy-success")[0] &&
  //     (document.getElementsByClassName(
  //       "text-copy-success"
  //     )[0].style.visibility = "visible");
  //   setTimeout(() => {
  //     document.getElementsByClassName("text-copy-success")[0] &&
  //       (document.getElementsByClassName(
  //         "text-copy-success"
  //       )[0].style.visibility = "hidden");
  //   }, 2000);
  //   return result;
  // };
  const { lang_json } = useSelector(
    (state) => ({
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );
  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-congrats`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">
        CONGRATULATIONS
      </div>
      <div className="modal-content">
        <div
          className="reward"
          style={{ backgroundImage: `url("${lang_json?.bg_reward_frame}")` }}
        >
          <div
            className="img-reward"
            style={{ backgroundImage: `url("${popup_payload?.cdn}")` }}
          />
        </div>
        {popup_payload?.is_physical ? (
          <h4>You have got {popup_payload?.item_name}!</h4>
        ) : (
          <h4>You got {popup_payload?.item_name} !!</h4>
        )}
        {popup_payload?.is_physical ? (
          <h4 className="congrats-text-desc">
            <span className="bold">{popup_payload?.voucher_code}</span> Please
            check your ingame mail within 7x24 hours for the reward delivery
            instructions.
          </h4>
        ) : (
          <h4 className="congrats-text-desc">
            {lang_json?.popup_text_physical_reward}
          </h4>
        )}
      </div>
      <div className="modal-button">
        <div
          className={`btn ${"btn-close"}`}
          onClick={close_func}
          style={{ backgroundImage: `url("${lang_json?.bg_btn_ok}")` }}
        ></div>
      </div>
    </Modal>
  );
};

export default Congrats;
