import React, { useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { post_form } from "redux/actions/main";
import { useForm } from "react-hook-form";
import { toggle_popup } from "../../../redux/actions/main";

const ModalName = "form";
// const screenOrientation = "portrait";
const Form = ({ close_func, popup_open, popup_type, popup_can_close }) => {
  const dispatch = useDispatch();
  const { form, err_form, lang_json } = useSelector(
    (state) => ({
      form: state.main.form,
      err_form: state.main.err_form,
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );

  const { register, handleSubmit, formState, setValue, reset, setError } =
    useForm();
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = () => {
    setFocused(false);
  };

  const { isDirty, isValid, errors } = formState;

  const alphabet = (e) => {
    const keyCode = e.which ? e.which : e.keyCode;
    const regex = /^[ A-Za-z_@./#&+-]*$/;
    const str = String.fromCharCode(keyCode);
    if (!regex.test(str)) e.preventDefault();
  };
  const numeric = (e) => {
    const keyCode = e.which ? e.which : e.keyCode;
    const regex = /^[0-9]*$/;
    const str = String.fromCharCode(keyCode);
    if (!regex.test(str)) e.preventDefault();
  };

  const onClick = (data) => {
    if (isDirty || isValid) {
      dispatch(post_form(data));
    }
  };

  useEffect(() => {
    if (err_form?.age === false) setError("age");
    if (err_form?.full_name === false) setError("full_name");
    if (err_form?.country_code === false) setError("country_code");
    if (err_form?.phone === false) setError("phone_number");
    if (err_form?.email === false) setError("email");
    if (err_form?.full_address === false) setError("full_address");
  }, [err_form, setError]);
  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal
     big
         modal-form `,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "fadeIn",
        modalAnimationOut: "fadeOut",
      }}
      animationDuration={500}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={() => {
        close_func();
        reset(form);
      }}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">{lang_json?.txt_popup_title_form ?? "REWARD FORM"}</div>
      <div className={`modal-content`}>
        <div className="form-container">
          <div className="form-group">
            <label>Full Name</label>
            <p>:</p>
            <input
              type="text"
              defaultValue={form?.full_name}
              placeholder="John Doe"
              autoComplete="off"
              style={
                errors?.full_name
                  ? { border: "0.2rem solid red" }
                  : { border: "none" }
              }
              name="full_name"
              onKeyPress={alphabet}
              {...register("full_name", { required: true })}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
            />
          </div>
          <div className="form-group">
            <label>Age</label>
            <p>:</p>
            <input
              autoComplete="off"
              defaultValue={form?.age}
              placeholder="18"
              style={
                errors?.age
                  ? { border: "0.2rem solid red" }
                  : { border: "none" }
              }
              type="number"
              name="age"
              onKeyPress={numeric}
              {...register("age", {
                required: true,
                min: 0,
                max: 100,
              })}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
            />
          </div>
          <div className="form-group">
            <label>Phone number</label>
            <p>:</p>
            <div className="group">
              <select
                id="code"
                style={
                  errors?.country_code
                    ? { border: "0.2rem solid red" }
                    : { border: "none" }
                }
                name="country_code"
                defaultValue={form?.country_code}
                {...register("country_code", {
                  required: true,
                })}
              >
                <option defaultValue={"62" === form?.country_code} value="62">
                  +62
                </option>
                <option defaultValue={"60" === form?.country_code} value="60">
                  +60
                </option>
                <option defaultValue={"63" === form?.country_code} value="63">
                  +63
                </option>
                <option defaultValue={"65" === form?.country_code} value="65">
                  +65
                </option>
              </select>
              <input
                autoComplete="off"
                defaultValue={form?.phone}
                placeholder="8232345xxx"
                style={
                  errors?.phone_number
                    ? { border: "0.2rem solid red" }
                    : { border: "none" }
                }
                type="tel"
                name="phone_number"
                onKeyPress={numeric}
                {...register("phone_number", {
                  required: true,
                  maxLength: 15,
                })}
                onFocus={() => onFocus()}
                onBlur={() => onBlur()}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <p>:</p>
            <input
              autoComplete="off"
              defaultValue={form?.email}
              placeholder="johndoe@gmail.com"
              style={
                errors?.email
                  ? { border: "0.2rem solid red" }
                  : { border: "none" }
              }
              type="email"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
            />
          </div>
          <div className="form-group">
            <label>Address</label>
            <p>:</p>
            <input
              autoComplete="off"
              defaultValue={form?.address}
              placeholder="Austin Avenue 686, South Jakarta 31405"
              style={
                errors?.full_address
                  ? { border: "0.2rem solid red" }
                  : { border: "none" }
              }
              type="text"
              name="full_address"
              {...register("full_address", { required: true })}
              onFocus={() => onFocus()}
              onBlur={() => onBlur()}
            />
          </div>
          <div className="checkbox-group">
            <div className="form-checkbox">
              <input
                autoComplete="off"
                checked={form?.agreement1}
                style={
                  errors?.agreement1
                    ? { outline: "0.2rem solid red" }
                    : { outline: "none" }
                }
                className="checkbox"
                type="checkbox"
                name="agreement1"
                {...register("agreement1", { required: true })}
                onFocus={() => onFocus()}
              />
              <label>
                I have read and agree with{" "}
                <span
                  className="link"
                  onClick={() =>
                    dispatch(
                      toggle_popup(true, "tos", true, {
                        cb: setValue,
                      })
                    )
                  }
                >
                  Terms of Service
                </span>
              </label>
            </div>
            <div className="form-checkbox">
              <input
                autoComplete="off"
                checked={form?.agreement2}
                style={
                  errors?.agreement2
                    ? { outline: "0.2rem solid red" }
                    : { outline: "none" }
                }
                className="checkbox"
                type="checkbox"
                name="agreement2"
                {...register("agreement2", { required: true })}
                onFocus={() => onFocus()}
              />
              <label>
                I hereby declare that the information provided is true and
                correct
              </label>
            </div>
            <div className="form-checkbox">
              <input
                autoComplete="off"
                checked={form?.agreement3}
                style={
                  errors?.agreement3
                    ? { outline: "0.2rem solid red" }
                    : { outline: "none" }
                }
                className="checkbox"
                type="checkbox"
                name="agreement3"
                {...register("agreement3", { required: true })}
                onFocus={() => onFocus()}
              />
              <label>
                If there's incorrect data, you won't be eligible to claim the
                rewards
              </label>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal-button"
        onClick={() => focused && setFocused(false)}
      >
        <div
          className="btn btn-submit"
          onClick={handleSubmit(onClick)}
          style={{ backgroundImage: `url("${lang_json?.bg_btn_submit}")` }}
        ></div>
      </div>
    </Modal>
  );
};

export default Form;
