import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";

const ModalName = "eventinfo";

const EventInfo = ({
  close_func,
  popup_open,
  popup_type,
  popup_payload,
  popup_can_close,
}) => {
  const { lang_json } = useSelector(
    (state) => ({
      lang_json: state.main.lang_json,
    }),
    shallowEqual
  );

  return (
    <Modal
      open={ModalName === popup_type ? popup_open : false}
      classNames={{
        modal: `modal modal-eventinfo`,
        overlay: "overlay",
        closeButton: "btn close-button",
        closeIcon: "close-icon",
        modalAnimationIn: "slideInUp",
        modalAnimationOut: "slideOutDown",
      }}
      animationDuration={250}
      center
      focusTrapped={false}
      showCloseIcon={popup_can_close}
      closeOnOverlayClick={popup_can_close}
      closeOnEsc={popup_can_close}
      onClose={close_func}
      blockScroll={false}
      styles={{
        modal: { backgroundImage: `url(${lang_json?.img_bg_popup_big})` },
      }}
    >
      <div className="modal-title">{lang_json?.txt_popup_title_details ??  "DETAILS"}</div>
      <div className="modal-content">
        <div
          className="event-info"
          dangerouslySetInnerHTML={{
            __html: lang_json?.event_info,
          }}
        />
      </div>
    </Modal>
  );
};

export default EventInfo;
